import React, { useEffect, useState } from 'react';

import Button from './base/Button';
import Text from './base/Text';

const PaginationFooter = ({ items, page, setPage }: { items: any[], page: number, setPage: (n: number) => void }) => {

  const [lowerBound, setLowerBound] = useState<number>(1);
  const [upperBound, setUpperBound] = useState<number>(10);
  const [maxPage, setMaxPage] = useState<number>(1)

  useEffect(() => {
    setLowerBound(page * 10 + 1);
    setUpperBound(Math.min((page + 1) * 10, items.length));
    setMaxPage(Math.ceil(items.length / 10) - 1);
  }, [items, page])

  return (
    <div style={{
      position: "absolute",
      bottom: "25px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "calc(100% - 85px)"
    }}>
      <Text size={20}>
        { items.length > 1 && `Showing ${lowerBound}-${upperBound} of ${items.length}` }
        { items.length == 1 && `Showing 1 of 1` }
        { items.length == 0 && `Showing 0 of 0`}
      </Text>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}>
        <img
          style={{ width: "20px", rotate: "90deg", opacity: page == 0 ? "0.5" : undefined, cursor: "pointer" }}
          src={process.env.PUBLIC_URL + "/assets/down-arrow.png"}
          onClick={() => {
            if (page > 0) setPage(page - 1);
          }}
        />
        <Text size={20}>
          {page + 1}
        </Text>
        <img
          style={{ width: "20px", rotate: "-90deg", opacity: page == maxPage ? "0.5" : undefined, cursor: "pointer" }}
          src={process.env.PUBLIC_URL + "/assets/down-arrow.png"}
          onClick={() => {
            if (page < maxPage) setPage(page + 1);
          }}
        />
      </div>

    </div>
  );
};

export default PaginationFooter;
