import React, { ReactNode, useCallback, useState } from 'react';
import { useCookies } from "react-cookie";

import './FileDrop.css';
import { s3Presign, updateDocument, uploadDocument } from '../requests';

interface PresignData {
  url: string;
  fields: {
    [key: string]: string | Blob; // Specifying that fields can be string or Blob
  };
}

const FileDrop = ({ documentType, setErrorMessage, retrieveDocuments, documentsLength, category, clientId, setClientId, associatedDocumentId, children }: {
  documentType: string,
  setErrorMessage: (s: string) => void,
  retrieveDocuments: () => void,
  documentsLength: number,
  category: string,
  clientId: string,
  setClientId: (s: string) => void,
  associatedDocumentId?: string
  children: ReactNode
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [dragOver, setDragOver] = useState(false);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    if (!dragOver) setDragOver(true);
  }, []);

  const onFileUpload = async (file) => {
    try {
      const fileKey = `${Date.now()}_${file.name}`;
      const resp = await uploadDocument(cookies['user-id'], fileKey, category, clientId, associatedDocumentId, file.name);
      await retrieveDocuments();

      // Get the pre-signed URL from the Flask backend
      const presignResponse = await s3Presign(cookies['user-id'], fileKey);
      const presignData: PresignData = presignResponse.body["url"];

      // Prepare the form data for posting the PDF file
      const formData = new FormData();
      Object.entries(presignData.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);

      // Post the file to the S3 bucket using the pre-signed URL
      const uploadResponse = await fetch(presignData.url, {
        method: 'POST',
        body: formData
      });

      if (uploadResponse.ok) {
        await updateDocument(cookies['user-id'], resp.body['document_instance_id'], "uploaded", "", fileKey);
      } else {
        throw new Error('Upload failed');
      }
    } catch (err) {
      console.error('Error uploading file:', err);
    }

  }

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      e.preventDefault();
      onFileUpload(e.dataTransfer.files[0]); // Assuming single file upload, adjust if necessary
      e.preventDefault();
      e.dataTransfer.clearData();
    }
  }, [onFileUpload]);

  return (
    <div
      className={`${documentsLength == 0 ? 'drop-zone' : ''} ${dragOver ? 'drag-over' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        minHeight: "375px",
        borderRadius: "8px"
      }}
    >
      {children}
    </div>
  );
};

export default FileDrop;
