import React, { useState, useRef, useEffect, ReactNode } from "react";
import Switch from '@mui/material/Switch';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentStatus, DocumentType } from "./Document";
import Button from "./base/Button";
import { analyzePolicy, comparePolicyCoverage, deleteDocument, generateEditedReport, generateProposal, getDocumentResults, getPresignedUrl, setDisplayName, updateCoverageLines } from "../requests";
import { useCookies } from "react-cookie";
import AISearch from "./AISearch";
import LoadingSpinner from "./base/Spinner";
import { Viewer, Worker, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Space from "./base/Space";
import './base/Button.css';
import FileDrop from "./FileDrop";
import NewDocument from "./NewDocument";
import Container from "./Container";

// Import styles  - test change
import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { highlightPlugin, HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { Trigger } from '@react-pdf-viewer/highlight';
import ResultItem from "./ResultItem";
import Dropdown from "./base/Dropdown";
import FeedbackFooter from "./FeedbackFooter";
import MarkdownPage from "./MarkdownPage";
import NewResultItem from "./NewResultItem";

// test update
const coverageLines = [
  "Cyber",
  "D&O",
  "Professional Liability",
  "Commercial Property",
  "General Liability",
  "BOP",
  "Garage",
  "EPL",
  "Crime",
  "Pollution Liability",
  "Logging",
  "Builder's Risk",
  "Commercial Auto",
  "Inland Marine",
  "Umbrella"
]

// interface HighlightArea {
//   height: number;
//   left: number;
//   pageIndex: number;
//   top: number;
//   width: number;
// }


// coverageType: coverage, endorsement, schedule, generic
// coverageLine: only relevant to coverage/endorsements
// what line it belongs to

// keys: all the values to display under result that's editable

export interface ResultWithReference {
  // The generated unique identifier
  id: string;

  // The result content
  coverageName: string;

  coverageType: string;

  coverageLine: string;

  keys: any;

  // The list of highlight areas
  highlightArea: HighlightArea;

  // String of Page Ref information
  pageIndex: string;
}

const staticResultObjects: ResultWithReference[] = [
  {
    id: "1",
    coverageName: "example endorsement",
    coverageType: "endorsement",
    coverageLine: "Cyber",
    keys: {
      "description": "This is a description blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ",
    },
    highlightArea: {
      height: 0,
      width: 0,
      left: 0,
      top: 0,
      pageIndex: 0
    },
    pageIndex: "0"
  },
  {
    id: "2",
    coverageName: "example schedule",
    coverageType: "schedule",
    coverageLine: "Cyber",
    keys: {
      "schedule": `| Location/Premises Number | Building Number | Addresses                                      | Building Coverage | Business Personal Property Coverage | Business Income With Extra Expense |
|-----------------|---------------------------|------------------------------------------------|-------------------|-------------------------------------|------------------------------------|
| Location 1      | Building 1                | 3799 N PINE ISLAND RD, SUNRISE, FL 33351-6580  | $4,500,000        | $500,000                            | $2,991,857                        |
| Location 2      | Building 1                | 200 DIXIELAND DR, FORT PIERCE, FL 34982-6709   | $2,080,000        | $170,000                            | $1,755,308                        |
| Location 2      | Building 2                | 200 DIXIELAND DR, FORT PIERCE, FL 34982-6709   | $520,000          | $30,000                             | $749,361                          |
| Location 3      | Building 1                | 9057 NW 57TH ST, TAMARAC, FL 33351-4305        | $3,414,520        | $162,240                            | $1,000,000                        |
| Location 4      | Building 1                | 6026 OLD CONGRESS RD, LANTANA, FL 33462-2430   | $3,724,464        | $500,000                            | $800,000                          |
| Location 5      | Building 1                | 4001 Stack Blvd., Melbourne, FL 32901, Brevard | $11,475,500       | $400,000                             | $2,700,000                        |`,
    },
    highlightArea: {
      height: 0,
      width: 0,
      left: 0,
      top: 0,
      pageIndex: 0
    },
    pageIndex: "0"
  }
]

const scheduleLineOptions = [
  'form_schedule', 
  'umbrella_schedule',
  'vehicle_list',
  'address_list',
  'garage_addresses',
  'driver_list',
  'contractor_property_list'
];

const PDFModal = ({ setModalOpen, modalOpen, document, documents, setDocument, retrieveDocuments, setSuccessMessage, setErrorMessage, secondDocument, setSecondDocument, setComparisonModalOpen, agencyId, setClientId }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  document: DocumentType | null,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  setDocument: (d: DocumentType | null) => void,
  secondDocument: DocumentType | null,
  setSecondDocument: (d: DocumentType | null) => void,
  setSuccessMessage: (s: string) => void,
  setErrorMessage: (s: string) => void,
  setComparisonModalOpen: (b: boolean) => void,
  agencyId: string,
  setClientId: (s: string) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [page, setPage] = useState<string>("normal");
  const [policyInfo, setPolicyInfo] = useState<string>("");
  const [generationStarted, setGenerationStarted] = useState<string | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [deleteButtonClicked, setDeleteButtonClicked] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [selectedLines, setSelectedLines] = useState<string[]>([]);
  const [objective, setObjective] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string>("");
  const [localDisplayName, setLocalDisplayName] = useState<string>("");
  const [endorsements, setEndorsements] = useState<DocumentType[]>([]);
  const [schedules, setSchedules] = useState<DocumentType[]>([]);
  const [markdownReport, setMarkdownReport] = useState<boolean>(true);
  const [additionalDocument, setAdditionalDocument] = useState<DocumentType | null>(null);
  const [additionalDocumentUrl, setAdditionalDocumentUrl] = useState<string>("");
  const [resultObjects, setResultObjects] = useState<ResultWithReference[]>([]);
  const [filteredResultObjects, setFilteredResultObjects] = useState<ResultWithReference[]>([]);
  const [selectedResultId, setSelectedResultId] = useState<string>("");
  const [selectedResult, setSelectedResult] = useState<ResultWithReference[]>([]);
  const [lineOptions, setLineOptions] = useState<string[]>([]);
  const [selectedLine, setSelectedLine] = useState<string>("");
  const [selectedCoverageType, setSelectedCoverageType] = useState<string>("generic");
  const [refreshEntries, setRefreshEntries] = useState<boolean>(false);
  const [proposalDocuments, setProposalDocuments] = useState<(DocumentType | null)[]>([]);
  const [scheduleResult, setScheduleResult] = useState<ResultWithReference | null>(null);

  const [periods, setPeriods] = useState('');

  useEffect(() => {
    if (document?.status == DocumentStatus.ANALYZING_POLICY) {
      const interval = setInterval(() => {
        setPeriods((prevPeriods) => (prevPeriods.length < 3 ? prevPeriods + '.' : ''));
      }, 500);
  
      return () => clearInterval(interval);
    }
  }, [document?.status]);

  const onScheduleClick = (r: ResultWithReference) => {
    setScheduleResult(r);
    setPage("markdown");
  }

  const onModalCloseInternal = () => {
    setDeleteButtonClicked(false);
    setPage("normal");
    setPresignedUrl("");
    setGenerationStarted(null);
    setSelectedResultId("");
    setSelectedResult([]);
  }

  const onGenerateEdited = async () => {
    await generateEditedReport(cookies['user-id'], document?.instanceId ?? "", markdownReport);
    
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const onGenerateProposal = async () => {
    await generateProposal(cookies['user-id'], [document?.instanceId ?? "", ...proposalDocuments.map(d => d?.instanceId ?? "")]);
    
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const onGenerate = async (reportType: string) => {
    setGenerationStarted(reportType);
    if (reportType != "comparison") {
      const policyResp = await analyzePolicy(cookies['user-id'], policyInfo, document?.instanceId ?? "", reportType ?? "", markdownReport);
      if (policyResp.body["bad_policy"]) {
        setErrorMessage("We are unable to generate reports for this coverage line. We apologize for this inconvenience.");
        setGenerationStarted(null);
      }
    } else {
      await comparePolicyCoverage(cookies['user-id'], [document?.instanceId ?? "", secondDocument?.instanceId ?? ""], markdownReport);
    }
    
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const deleteDoc = async () => {
    setDeleting(true);
    const resp = await deleteDocument(cookies['user-id'], document?.instanceId ?? "", document?.category != "policy");
    if (resp.status == 200) {
      setSuccessMessage("Document deleted successfully.");
      retrieveDocuments();
      setModalOpen(false);
      onModalCloseInternal();
    } else {
      setErrorMessage("Unable to delete the document at this time. Please try again in a minute.")
    }

    setDeleting(false);
  }

  const deleteAdditionalDoc = async () => {
    setDeleting(true);
    const resp = await deleteDocument(cookies['user-id'], additionalDocument?.instanceId ?? "", false);
    if (resp.status == 200) {
      setSuccessMessage("Document deleted successfully.");
      retrieveDocuments();
      setDeleteButtonClicked(false);
      setPage("additionalDocuments");
    } else {
      setErrorMessage("Unable to delete the document at this time. Please try again in a minute.")
    }

    setDeleting(false);
  }

  const saveDisplayName = async () => {
    const resp = await setDisplayName(cookies['user-id'], document?.instanceId ?? "", editedName, document?.category != "policy");
    if (resp.status == 200) {
      retrieveDocuments();
      setLocalDisplayName(editedName);
      setEditingName(false);
    } else {
      setErrorMessage("Unable to edit document name at this time. Please try again in a minute.")
    }
    
  }

  const onViewAdditionalDocument = async (d: DocumentType) => {
    const resp = await getPresignedUrl(cookies['user-id'], d?.link ?? "", d?.instanceId ?? "", false, false);
    setAdditionalDocument(d);
    setAdditionalDocumentUrl(resp.body["url"]);
    setPage("viewAdditionalDocument");
  }

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {selectedResult.map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: 'yellow',
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({renderHighlights, trigger: Trigger.None});
  const { jumpToHighlightArea } = highlightPluginInstance;

  useEffect(() => {
    const findResultById = (results: ResultWithReference[], id: string): ResultWithReference | undefined => {
      return results.find(result => result.id === id);
    };
    

    async function updateHighlights() {
      const foundResult = findResultById(resultObjects, selectedResultId);
      if (foundResult === undefined) {
        console.log("NOTHING CLICKED, RESULT NOT FOUND")
      } else {
        console.log("FOUND RESULT");
        console.log(foundResult);
        setSelectedResult([foundResult]);
      }
    }

    updateHighlights();

    return () => {};

  }, [resultObjects, selectedResultId])


  useEffect(() => {
    let ends: DocumentType[] = [];
    let schds: DocumentType[] = [];
    documents.filter(d => d.associatedDocumentId == document?.instanceId).forEach(d => {
      if (d.category == "endorsement") ends.push(d);
      else if (d.category == "schedule") schds.push(d);
    })

    setEndorsements(ends);
    setSchedules(schds);
  }, [document, documents])

  useEffect(() => {
    setLocalDisplayName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    setEditedName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    const translateResultsToReactInterface = (results: any[]): ResultWithReference[] => {
      return results.map((result) => {
        return {
          id: result.coverage_id,
          coverageName: result.coverage_name,
          coverageType: result.coverage_type,
          coverageLine: (
            scheduleLineOptions.includes(result.coverage_line)
              ? "Schedules"
              : (result.coverage_line ?? '')
          ),
          keys: result.keys,
          highlightArea: {
            height: result.highlight_area.height,
            width: result.highlight_area.width,
            left: result.highlight_area.left,
            top: result.highlight_area.top,
            pageIndex: result.highlight_area.page_index
          },
          pageIndex: result.page_index
        };
      });
    };
    
    async function retrieveDocumentResults() {
      if (modalOpen) {
        try {
          const resp = await getDocumentResults(cookies['user-id'], document?.instanceId ?? "");
          const translatedResults = translateResultsToReactInterface(resp.body["results"]);
          setResultObjects(translatedResults);
          let newLines: string[] = [];
          for (let i = 0; i < translatedResults.length; i++) {
            const newLine = translatedResults[i].coverageLine;
            if (newLine != "" && !newLines.includes(newLine)) {
              newLines.push(newLine);
            }
          }
          setLineOptions(newLines);
          console.log("RESPONSE RESULTS");
          console.log(translatedResults);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }
  
    retrieveDocumentResults(); // Initial call

    if (refreshEntries) {
      setRefreshEntries(false);
    }
  
    return () => {};
  
  }, [document, document?.instanceId, document?.category, modalOpen, refreshEntries]);

  useEffect(() => {
    setFilteredResultObjects(
      resultObjects.filter(
        r => (
          (r.coverageLine == selectedLine && r.coverageType == selectedCoverageType) || 
          (r.coverageType == "generic" && selectedCoverageType == "generic")
        )
      )
    );
    console.log(resultObjects);
  }, [resultObjects, selectedLine, selectedCoverageType])


  useEffect(() => {
    let intervalId;
  
    async function retrievePresignedUrl() {
      if (modalOpen) {
        try {
          const resp = await getPresignedUrl(cookies['user-id'], document?.link ?? "", document?.instanceId ?? "", (document?.category == "comparison" || document?.category == "analysis"), document?.category == "proposal");
          setPresignedUrl(resp.body["url"]);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }
  
    retrievePresignedUrl(); // Initial call
  
    if (modalOpen) {
      intervalId = setInterval(retrievePresignedUrl, 59 * 60 * 1000); // Set interval for 59 minutes
    }
  
    return () => {
      clearInterval(intervalId); // Clear interval on component unmount or dependencies change
    };
  
  }, [document, document?.instanceId, document?.category, modalOpen]);

  useEffect(() => {
    setSelectedLines(document?.lines ?? []);
    if (document?.lines && document?.lines.length > 0) {
      setSelectedLine(document?.lines[0]);
    }
  }, [document, document?.lines, modalOpen])

  const onLineClick = (l: string) => {
    if (!!selectedLines.find(l2 => l2 == l)) {
      setSelectedLines(selectedLines.filter(l2 => l2 != l));
    } else {
      setSelectedLines(prev => [...prev, l]);
    }
  }

  const onConfirmLines1 = async () => {
    setLoading(true);
    await updateCoverageLines(cookies['user-id'], document?.instanceId ?? "", selectedLines);
    if (objective == "report") {
      await onGenerate("internal");
    } else {
      setSelectedLines(selectedDocument?.lines ?? []);
      setPage("confirmLines2");
    }
    setLoading(false);
  }

  const onConfirmLines2 = async () => {
    setLoading(true);
    await updateCoverageLines(cookies['user-id'], secondDocument?.instanceId ?? "", selectedLines);
    await onGenerate("comparison");
    setLoading(false);
  }

  const onDocumentClick = (d2: DocumentType) => {
    setSelectedDocument(d2);
  }
  
  const onCompareSelect = async () => {
    setSecondDocument(selectedDocument);
    setPage("compare");
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="pdfModal" onModalClose={() => {
      onModalCloseInternal();
    }} extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "90%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      position: "relative"
    }}>
      { page == "normal" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: 'center',
              justifyContent: "flex-start",
              width: "90%",
              gap: "20px"
            }}>
              { !editingName && (
                <div style={{ maxWidth: "50%" }}>
                  <Text size={35} weight={600}>
                    {localDisplayName}
                  </Text>
                </div>
              )}
              { editingName && (
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.currentTarget.value)}
                  style={{
                    width: "90%",
                    borderRadius: "8px",
                    fontSize: "35px",
                    padding: "10px",
                    border: "1px solid #475569",
                    outline: "none",
                    fontFamily: "Assistant, sans-serif",
                  }}
                />
              )}
              { !editingName && (
                <img src={process.env.PUBLIC_URL + "/assets/edit.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => setEditingName(true)} />
              )}
              { editingName && (
                <>
                  <img src={process.env.PUBLIC_URL + "/assets/cancel.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => {
                    setEditedName(document?.name ?? "");
                    setEditingName(false);
                  }} />
                  <img src={process.env.PUBLIC_URL + "/assets/green-check.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={saveDisplayName} />
                </>
              )}
              { document?.category == "policy" && (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  {document?.status == "uploaded" && (
                    <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => {
                      setObjective("report");
                      setPage("confirmLines1");
                    }}> 
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px"
                      }}>
                        <img src={process.env.PUBLIC_URL + "/assets/ai.png"} style={{ width: "20px", height: "20px" }} />
                        <Text size={16}>
                          Analyze Policy
                        </Text>
                      </div>
                    </Button>
                  )}
                  {document?.status == "analyzed" && (
                    <>
                      { generationStarted != "internal" && (
                        <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={onGenerateEdited}> 
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px"
                          }}>
                            <img src={process.env.PUBLIC_URL + "/assets/ai.png"} style={{ width: "20px", height: "20px" }} />
                            <Text size={16}>
                              Report
                            </Text>
                          </div>
                        </Button>
                      )}
                      { ( agencyId == process.env.REACT_APP_TWIN_CITIES_AGENCY_ID || agencyId == process.env.REACT_APP_ISU_AGENCY_ID ) && (
                        <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => setPage("compare_proposal")}> 
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px"
                          }}>
                            <img src={process.env.PUBLIC_URL + "/assets/proposal.png"} style={{ width: "20px", height: "20px" }} />
                            <Text size={16}>
                              Proposal
                            </Text>
                          </div>
                        </Button>
                      )}
                      <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => setPage("compare")}> 
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: 'center',
                        }}>
                          <img src={process.env.PUBLIC_URL + "/assets/compare.png"} style={{ width: "20px", height: "20px" }} />
                          <Text size={16}>
                            Compare
                          </Text>
                        </div>
                      </Button>
                      { agencyId != process.env.REACT_APP_TWIN_CITIES_AGENCY_ID && (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <Text size={16}>
                            Text Report?
                          </Text>
                          <Switch checked={markdownReport} onChange={() => setMarkdownReport(!markdownReport)} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px"
            }}>
              { document?.category == "policy" && (
                <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setPage("additionalDocuments")}> 
                  <Text size={16}>
                    Upload
                  </Text>
                </Button>
              )}
              { !deleteButtonClicked && (
                <Button padding="10px" maxHeight="20px" color="white" border="1px solid red" onClick={() => setDeleteButtonClicked(true)}> 
                  <Text size={16} color="red">
                    Delete
                  </Text>
                </Button>
              )}
              { deleteButtonClicked && (
                <>
                  <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setDeleteButtonClicked(false)}> 
                    <Text size={16} color="black">
                      Cancel
                    </Text>
                  </Button>
                  { !deleting && (
                    <Button padding="10px" maxHeight="20px" color="red" onClick={deleteDoc}> 
                      <Text size={16} color="white">
                        Delete Document
                      </Text>
                    </Button>
                  )}
                  { deleting && (
                    <LoadingSpinner />
                  )}
                </>
              )}
            </div>
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            gap: "20px",
            height: "80%",
          }}>
            {/* <iframe
              src={presignedUrl}
              width="100%"
              height="110%"
            /> */}
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">  
                  {presignedUrl && (
                    <div style={{
                      border: '1px solid rgba(0, 0, 0, 0.3)',
                      width: "70%",
                      height: "100%",
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                    }}>
                      <Viewer fileUrl={presignedUrl} plugins={[highlightPluginInstance]} />
                              </div>
                              )}         
                </Worker>
            { (document?.instanceIds === undefined && document?.category == "policy") && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                height: "110%",
                overflowY: "scroll",
                width: "45%"
              }}>
                {/* <Text size={30} weight={600}>
                  Associated Reports
                </Text>
                { documents.filter(d => d.instanceIds?.includes(document?.instanceId ?? "")).map(d => (
                  <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                    setDocument(d);
                  }} />
                ))} */}
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "110%",
                  overflowY: "scroll",
                  position: "relative"
                }}>
                  <Text size={40} weight={600} align="center">
                    Policy Analysis
                  </Text>
                  { document?.status === "uploaded" && (
                    <>
                      <Space px={150} />
                      <Text size={40} color="gray" align="center">
                        Analyze policy to see results here
                      </Text>
                    </>
                  )}
                  { document?.status === "analyzing_policy" && (
                    <>
                      <Space px={100} />
                      <Text size={40} color="gray" align="center">
                        Analyzing{periods}
                      </Text>
                    </>
                  )}
                  { document?.status === "analyzed" && (
                    <>
                      <Space px={15} />
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "95%",
                        gap: "30px"
                      }}>
                        <Dropdown options={lineOptions.map(d => ({ value: d, label: d })) ?? []} option={selectedLine} setOption={setSelectedLine} label="Coverage Line" width="220px" />
                        <Dropdown options={[
                          {
                            label: "Key Information",
                            value: "generic"
                          },
                          {
                            label: "Coverage",
                            value: "coverage"
                          },
                          {
                            label: "Endorsement",
                            value: "endorsement",
                          },
                          {
                            label: "Schedule",
                            value: "schedule"
                          }]} option={selectedCoverageType} setOption={setSelectedCoverageType} label="Result Type" width="220px" />
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        gap: "10px"
                      }}>
                        <Space px={20} />
                        {filteredResultObjects.map(result => (
                          <ResultItem
                            result={result}
                            onClick={() => {
                              jumpToHighlightArea(result.highlightArea);
                              setSelectedResultId(result.id);
                            }}
                            selected={result.id == selectedResultId}
                            onDoubleClick={() => setSelectedResultId("")}
                            setRefresh={setRefreshEntries}
                            onScheduleClick={onScheduleClick}
                          />
                        ))}
                        { selectedCoverageType != "schedule" && (
                          <NewResultItem
                            coverageLine={selectedLine}
                            coverageType={selectedCoverageType}
                            documentInstanceId={document?.instanceId ?? ""}
                            onClick={() => setSelectedResultId("new_result")}
                            selected={"new_result" == selectedResultId}
                            onDoubleClick={() => setSelectedResultId("")}
                            setRefresh={setRefreshEntries}
                          />
                        )}
                        <Space px={50} />
                      </div>
                      <FeedbackFooter documentInstanceId={document.instanceId ?? ""} />
                    </>
                  )}
                </div>
              </div>
            )}
            { document?.status == "failed" && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                width: "50%"
              }}>
                <Text size={40} align="center" color="gray">
                  Analysis Failed
                </Text>
                <Space px={20} />
                <Text size={30} align="center">
                  What does this mean?
                </Text>
                <Text size={20} align="center">
                  Something about the policy wasn't what our system expected. We've been alerted, and we're looking into it ASAP. Don't worry — you weren't charged a credit for this one.
                </Text>
                <Space px={20} />
                <Text size={30} align="center">
                  What should I do?
                </Text>
                <Text size={20} align="center">
                  You can try re-analyzing the policy above, although that might not work. You can also reach out to us anytime at 281-224-0743, or matthew@coverflow.tech, to check on the status of our investigation.
                </Text>
              </div>

            )}
          </div>
          
        </>
      )}
      { page == "compare" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select a policy to compare with <div style={{ color: "#4854F4", display: "inline" }}>{localDisplayName}</div>.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "280px",
              height: "280px",
            }}>
              <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
              <Text size={30} weight={600} align="center">
                {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
              </Text>
            </div>
            <Text size={14} color="gray">
              and
            </Text>
            { !secondDocument && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                width: "280px",
                height: "280px",
                border: "1px solid black",
                cursor: "pointer"
              }} onClick={() => setPage("selecting")}>
                <img style={{ width: "75px", height: "75px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
              </div>
            )}
            { secondDocument && (
              <div style={{
                position: "relative"
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "280px",
                  height: "280px",
                }}>
                  <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                  <Text size={30} weight={600} align="center">
                    {secondDocument?.name && secondDocument?.name.length > 17 ? secondDocument?.name.slice(0, 17) + "..." : secondDocument?.name}
                  </Text>
                </div>
                <img style={{ width: "50px", height: "50px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => setSecondDocument(null)} />
              </div>
            )}
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("normal")}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button width="200px" height="55px" greyed={!secondDocument} onClick={() => {
                  setComparisonModalOpen(true);
                }}>
                  <Text size={20} weight={600} color="white">
                    Next
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "compare_proposal" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select any policies to add to the proposal.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "280px",
              height: "280px",
            }}>
              <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
              <Text size={30} weight={600} align="center">
                {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
              </Text>
            </div>
            <Text size={14} color="gray">
              and
            </Text>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              //alignItems: "center",
              //justifyContent: "center",
              width: "700px",
              height: "400px",
            }}>
              { proposalDocuments.map(d => (
                <div style={{
                  position: "relative"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "150px",
                    height: "150px",
                  }}>
                    <img style={{ width: "60px", height: "60px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                    <Text size={18} weight={600} align="center">
                      {d?.name && d?.name.length > 17 ? d.name.slice(0, 17) + "..." : d?.name}
                    </Text>
                  </div>
                  <img style={{ width: "30px", height: "30px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => {
                    setProposalDocuments(prev => prev.filter(d2 => d?.instanceId != d2?.instanceId))
                  }} />
                </div>
              ))}
              { proposalDocuments.length < 10 && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: "150px",
                  height: "150px",
                  border: "1px solid black",
                  cursor: "pointer"
                }} onClick={() => setPage("selecting_proposal")}>
                  <img style={{ width: "50px", height: "50px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
                </div>
              )}
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("normal")}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button width="200px" height="55px" onClick={onGenerateProposal}>
                  <Text size={20} weight={600} color="white">
                    Generate Proposal
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "selecting" && (
          <>
            
            <Text size={35} weight={600}>
              Select a policy to compare
            </Text>
            <Divider />
            <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%" }}>
              { documents?.filter(d => d.category == "policy" && d.instanceId != document?.instanceId && d.status == "analyzed").map(d => (
                <div style={{ cursor: "pointer" }} onClick={() => onDocumentClick(d)}>
                  <Document d={d} selecting selected={selectedDocument?.instanceId == d.instanceId} />
                </div>
              ))}
            </div>
            <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", gap: "20px" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare")}>
                <Text size={20} weight={600} color="#000000">
                  Cancel
                </Text>
              </Button>
              <Button greyed={!selectedDocument} onClick={onCompareSelect}>
                <Text size={16} weight={600} color="white">
                  Select Document
                </Text>
              </Button>
            </div>
          </>
      )}
      { page == "selecting_proposal" && (
          <>
            
            <Text size={35} weight={600}>
              Select a policy to add to the proposal
            </Text>
            <Divider />
            <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%" }}>
              { documents?.filter(d => d.category == "policy" && d.instanceId != document?.instanceId && d.status == "analyzed").map(d => (
                <div style={{ cursor: "pointer" }} onClick={() => onDocumentClick(d)}>
                  <Document d={d} selecting selected={selectedDocument?.instanceId == d.instanceId} />
                </div>
              ))}
            </div>
            <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", gap: "20px" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare")}>
                <Text size={20} weight={600} color="#000000">
                  Cancel
                </Text>
              </Button>
              <Button greyed={!selectedDocument} onClick={() => {
                setProposalDocuments(prev => [...prev, selectedDocument]);
                setPage("compare_proposal")
              }}>
                <Text size={16} weight={600} color="white">
                  Select Document
                </Text>
              </Button>
            </div>
          </>
      )}
      { page == "confirmLines1" && (
          <>
            <Text size={35} weight={600}>
              Confirm the coverage lines for <div style={{ color: "#4854F4", display: "inline" }}>{localDisplayName}</div>
            </Text>
            <Text size={15} color="gray">
              It's not required, but it will make your report significantly more accurate.
            </Text>
            <Divider />
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "32px", flexWrap: "wrap", width: "90%", margin: "auto", transform: "translateY(-10px)" }}>
              { coverageLines.map(l => (
                <div
                  className="button"
                  style={{
                    padding: "18px",
                    borderRadius: "60px",
                    cursor: "pointer",
                    backgroundColor: !!selectedLines.find(l2 => l == l2) ? "#008000" : "#A9A9A9"
                  }}
                  onClick={() => onLineClick(l)}
                >
                  <Text size={28} color="white" weight={600}>{l}</Text>
                </div>
              ))}
            </div>
            <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
                if (objective == "report") {
                  setPage("normal");
                } else {
                  setPage("compare");
                }
              }}>
                <Text size={20} weight={600} color="#000000">
                  Back
                </Text>
              </Button>
              { !loading && (
                <Button onClick={onConfirmLines1}>
                  <Text size={16} weight={600} color="white">
                    { objective == "report" ? "Generate" : "Confirm Lines" }
                  </Text>
                </Button>
              )}
              { loading && (
                <LoadingSpinner />
              )}
            </div>
          </>
      )}
      { page == "confirmLines2" && (
          <>
            <Text size={35} weight={600}>
              Confirm the coverage lines for <div style={{ color: "#4854F4", display: "inline" }}>{secondDocument?.name}</div>
            </Text>
            <Text size={15} color="gray">
              It's not required, but it will make your report significantly more accurate.
            </Text>
            <Divider />
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "32px", flexWrap: "wrap", width: "90%", margin: "auto", transform: "translateY(-10px)" }}>
              { coverageLines.map(l => (
                <div
                  className="button"
                  style={{
                    padding: "18px",
                    borderRadius: "60px",
                    cursor: "pointer",
                    backgroundColor: !!selectedLines.find(l2 => l == l2) ? "#008000" : "#A9A9A9"
                  }}
                  onClick={() => onLineClick(l)}
                >
                  <Text size={28} color="white" weight={600}>{l}</Text>
                </div>
              ))}
            </div>
            <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
                setPage("compare");
              }}>
                <Text size={20} weight={600} color="#000000">
                  Back
                </Text>
              </Button>
              { !loading && (
                <Button onClick={onConfirmLines2}>
                  <Text size={16} weight={600} color="white">
                    Generate
                  </Text>
                </Button>
              )}
              { loading && (
                <LoadingSpinner />
              )}
            </div>
          </>
      )}
      { page == "additionalDocuments" && (
          <div style={{ width: "100%"}}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}>
              <img style={{ width: "80px", height: "80px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/left-arrow.png"} onClick={() => setPage("normal")} />
              <Text size={35} weight={600}>
                Upload Additional Documents
              </Text>
            </div>
            <Divider />
            <Space px={20} />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "100%", height: "85%", justifyContent: "center" }}>
              <Container title="Endorsements" width="90%">
                <FileDrop documentType="endorsements" documentsLength={endorsements.length} retrieveDocuments={retrieveDocuments} category="endorsement" clientId={"default_client"} setErrorMessage={setErrorMessage} associatedDocumentId={document?.instanceId} setClientId={setClientId}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px", flexWrap: "wrap", width: "100%", justifyContent: endorsements.length == 0 ? "center" : undefined }}>
                    { endorsements.map(d => (
                      <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                        onViewAdditionalDocument(d);
                      }} />
                    ))}
                    <NewDocument documentsLength={endorsements.length} retrieveDocuments={retrieveDocuments} category="endorsement" clientId={"default_client"} associatedDocumentId={document?.instanceId} />
                  </div>
                </FileDrop>
              </Container>
            </div>
          </div>
      )}
      { page == "viewAdditionalDocument" && (
          <>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}>
              <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
              }}>
                <img style={{ width: "80px", height: "80px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/left-arrow.png"} onClick={() => setPage("additionalDocuments")} />
                <Text size={35} weight={600}>
                  {additionalDocument?.name ?? ""}
                </Text>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
              }}>
                { !deleteButtonClicked && (
                  <Button padding="10px" maxHeight="20px" color="white" border="1px solid red" onClick={() => setDeleteButtonClicked(true)}> 
                    <Text size={16} color="red">
                      Delete Document
                    </Text>
                  </Button>
                )}
                { deleteButtonClicked && (
                  <>
                    <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setDeleteButtonClicked(false)}> 
                      <Text size={16} color="black">
                        Cancel
                      </Text>
                    </Button>
                    { !deleting && (
                      <Button padding="10px" maxHeight="20px" color="red" onClick={deleteAdditionalDoc}> 
                        <Text size={16} color="white">
                          Delete Document
                        </Text>
                      </Button>
                    )}
                    { deleting && (
                      <LoadingSpinner />
                    )}
                  </>
                )}
              </div>
            </div>
            <Divider />
            <iframe
              src={additionalDocumentUrl}
              width="100%"
              height="110%"
            />
          </>
      )}
      { page == "markdown" && (
        <MarkdownPage result={scheduleResult} setPage={setPage} />
      )}
    </Modal>
  )
};

export default PDFModal;



/*
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "45%",
                justifyContent: "center",
                alignContent: "center",
                padding: "10px",
                border: "2px solid black",
                borderRadius: "16px"
              }}>
                <Text size={30} weight={600} align="center">
                  Endorsements
                </Text>
                */