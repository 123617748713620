import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
//import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import { Scrollbar } from "react-scrollbars-custom";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";

import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import Container from "./components/Container";
import Document, { DocumentStatus, DocumentType } from "./components/Document";
import NewDocument from "./components/NewDocument";
import FileDrop from "./components/FileDrop";

import PaginationFooter from "./components/PaginationFooter";
import Search from "./components/Search";

const Documents = React.memo(({ clientId, setClientId, agencyId, documents, retrieveDocuments, loadingDocuments, onDocumentClick, setErrorMessage }: { 
  clientId: string,
  agencyId: string,
  setClientId: (s: string) => void,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  loadingDocuments: boolean,
  onDocumentClick: (d: DocumentType) => void,
  setErrorMessage: (s: string) => void
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [policies, setPolicies] = useState<DocumentType[]>([]);
  const [analyses, setAnalyses] = useState<DocumentType[]>([]);
  const [comparisons, setComparisons] = useState<DocumentType[]>([]);
  const [proposals, setProposals] = useState<DocumentType[]>([]);

  const [filteredPolicies, setFilteredPolicies] = useState<DocumentType[]>([]);
  const [filteredAnalyses, setFilteredAnalyses] = useState<DocumentType[]>([]);
  const [filteredComparisons, setFilteredComparisons] = useState<DocumentType[]>([]);
  const [filteredProposals, setFilteredProposals] = useState<DocumentType[]>([]);

  const [policiesQuery, setPoliciesQuery] = useState<string>("");
  const [analysesQuery, setAnalysesQuery] = useState<string>("");
  const [comparisonsQuery, setComparisonsQuery] = useState<string>("");
  const [proposalsQuery, setProposalsQuery] = useState<string>("");

  const [policiesPage, setPoliciesPage] = useState<number>(0);
  const [analysesPage, setAnalysesPage] = useState<number>(0);
  const [comparisonsPage, setComparisonsPage] = useState<number>(0);
  const [proposalsPage, setProposalsPage] = useState<number>(0);

  useEffect(() => {
    let pols: DocumentType[] = [];
    let anls: DocumentType[] = [];
    let cmps: DocumentType[] = [];
    let prps: DocumentType[] = [];
    documents.forEach(d => {
      if (d.category == "policy") pols.push(d);
      else if (d.category == "analysis") anls.push(d);
      else if (d.category == "comparison") cmps.push(d);
      else if (d.category == "proposal") prps.push(d);
    })

    setPolicies(pols.filter(d => !d.associatedDocumentId));
    setAnalyses(anls);
    setComparisons(cmps);
    setProposals(prps);
  }, [documents])

  useEffect(() => {
    onPoliciesSearch();
  }, [policiesQuery, policies])

  useEffect(() => {
    onAnalysesSearch();
  }, [analysesQuery, analyses])

  useEffect(() => {
    onComparisonsSearch();
  }, [comparisonsQuery, comparisons])

  useEffect(() => {
    onProposalsSearch();
  }, [proposalsQuery, proposals])

  const onPoliciesSearch = () => {
    setPoliciesPage(0);
    setFilteredPolicies(policies.filter(p => (
      p.name.toLowerCase().includes(policiesQuery.toLowerCase())
    )));
  }

  const onAnalysesSearch = () => {
    setAnalysesPage(0);
    setFilteredAnalyses(analyses.filter(a => (
      a.name.toLowerCase().includes(analysesQuery.toLowerCase())
    )));
  }

  const onComparisonsSearch = () => {
    setComparisonsPage(0);
    setFilteredComparisons(comparisons.filter(c => (
      c.name.toLowerCase().includes(comparisonsQuery.toLowerCase())
    )));
  }

  const onProposalsSearch = () => {
    setProposalsPage(0);
    setFilteredProposals(proposals.filter(p => (
      p.name.toLowerCase().includes(proposalsQuery.toLowerCase())
    )));
  }


  return (
    <div style={{
      position: "fixed",
      height: "100%",
      top: "100px",
      left: "205px",
      padding: "53px 60px 0px 60px",
    }}>
      <Scrollbar style={{ width: "calc(100vw - 270px)", height: "100%", transform: "translateY(-60px)" }}>
      <div style={{ transform: "translateY(60px)"}}>

      <Text size={40} color="#787878">
        Documents
      </Text>
      <Space px={25} />
      <Container key="policyContainer" minHeight="375px" title="Policies" zIndex={9}>
        { loadingDocuments && (
          <>
            <Space px={140} />
            <LoadingSpinner />
          </>
        )}
        { !loadingDocuments && (
          <>
            { policies.length > 0 && (
              <>
                <Search onSearch={onPoliciesSearch} query={policiesQuery} setQuery={setPoliciesQuery} />
                <Space px={50} />
              </>
            )}
            <FileDrop documentType="policies" documentsLength={policies.length} retrieveDocuments={retrieveDocuments} category="policy" clientId={clientId} setErrorMessage={setErrorMessage} setClientId={setClientId}>
              <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: policies.length == 0 ? "center" : undefined }}>
                { filteredPolicies.slice(policiesPage * 10, (policiesPage + 1) * 10).map(d => (
                  <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments}onClick={onDocumentClick} />
                ))}
                <NewDocument documentsLength={policies.length} retrieveDocuments={retrieveDocuments} category="policy" clientId={clientId} />
              </div>
              { policies.length > 0 && (
                <>
                  <Space px={50} />
                  <PaginationFooter items={filteredPolicies} page={policiesPage} setPage={setPoliciesPage} />
                </>
              )}
            </FileDrop>
          </>
        )}
      </Container>
      <Space px={25} />
      <Container key="analysisContainer" minHeight="375px" title="Policy Reports">
        { loadingDocuments && (
          <>
            <Space px={140} />
            <LoadingSpinner />
          </>
        )}
        { !loadingDocuments && (
          <>
            { analyses.length > 0 && (
              <>
                <Search onSearch={onAnalysesSearch} query={analysesQuery} setQuery={setAnalysesQuery} />
                <Space px={50} />
              </>
            )}
            <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: analyses.length == 0 ? "center" : undefined }}>
              { analyses.length > 0 && filteredAnalyses.slice(analysesPage * 10, (analysesPage + 1) * 10).map(d => (
                <Document key={d.instanceId + " report"} d={d} retrieveDocuments={retrieveDocuments} onClick={d.status === DocumentStatus.GENERATED || d.status === DocumentStatus.FAILED ? onDocumentClick : undefined} />
              ))}
              { analyses.length == 0 && (
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "140px"
                }}>
                  <Text size={30} color="#787878" align="center">
                    Click on a policy above to generate your first report.
                  </Text>
                </div>
              )}
            </div>
            { analyses.length > 0 && (
              <>
                <Space px={50} />
                <PaginationFooter items={filteredAnalyses} page={analysesPage} setPage={setAnalysesPage} />
              </>
            )}
          </>
        )}
      </Container>
      { agencyId == process.env.REACT_APP_TWIN_CITIES_AGENCY_ID && (
        <>
          <Space px={25} />
          <Container key="proposalContainer" minHeight="375px" title="Policy Proposals">
            { loadingDocuments && (
              <>
                <Space px={140} />
                <LoadingSpinner />
              </>
            )}
            { !loadingDocuments && (
              <>
                { proposals.length > 0 && (
                  <>
                    <Search onSearch={onProposalsSearch} query={proposalsQuery} setQuery={setProposalsQuery} />
                    <Space px={50} />
                  </>
                )}
                <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: proposals.length == 0 ? "center" : undefined }}>
                  { proposals.length > 0 && filteredProposals.slice(proposalsPage * 10, (proposalsPage + 1) * 10).map(d => (
                    <Document key={d.instanceId + " report"} d={d} retrieveDocuments={retrieveDocuments} onClick={d.status === DocumentStatus.GENERATED || d.status === DocumentStatus.FAILED ? onDocumentClick : undefined} />
                  ))}
                  { proposals.length == 0 && (
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                      marginTop: "140px"
                    }}>
                      <Text size={30} color="#787878" align="center">
                        Click on a policy above to generate your first proposal.
                      </Text>
                    </div>
                  )}
                </div>
                { proposals.length > 0 && (
                  <>
                    <Space px={50} />
                    <PaginationFooter items={filteredProposals} page={proposalsPage} setPage={setProposalsPage} />
                  </>
                )}
              </>
            )}
          </Container>
        </>
      )}
      <Space px={25} />
      <Container key="comparisonContainer" minHeight="375px" title="Policy Comparisons">
        { loadingDocuments && (
          <>
            <Space px={140} />
            <LoadingSpinner />
          </>
        )}
        { !loadingDocuments && (
          <>
            { comparisons.length > 0 && (
              <>
                <Search onSearch={onComparisonsSearch} query={comparisonsQuery} setQuery={setComparisonsQuery} />
                <Space px={50} />
              </>
            )}
            <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: comparisons.length == 0 ? "center" : undefined }}>
              { comparisons.length > 0 && filteredComparisons.slice(comparisonsPage * 10, (comparisonsPage + 1) * 10).map(d => (
                <Document key={d.instanceId + " report"} d={d} retrieveDocuments={retrieveDocuments} onClick={d.status === DocumentStatus.GENERATED || d.status === DocumentStatus.FAILED ? onDocumentClick : undefined} />
              ))}
              { comparisons.length == 0 && (
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "140px"
                }}>
                  <Text size={30} color="#787878" align="center">
                    Click on a policy above to generate your first comparison.
                  </Text>
                </div>
              )}
            </div>
            { comparisons.length > 0 && (
              <>
                <Space px={50} />
                <PaginationFooter items={filteredComparisons} page={comparisonsPage} setPage={setComparisonsPage} />
              </>
            )}
          </>
        )}
      </Container>
      <Space px={300} />
      </div>
      </Scrollbar>
    </div>
  );
});

export default Documents;
