import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';

import Title from "./base/Title";
import Text from "./base/Text";
import "./AppHeader.css";
import TextWithBorder from "./TextWithBorder";
import Spacer from "./base/Spacer";
import Divider from "./base/Divider";
import Space from "./base/Space";

import './Container.css';

const Container = ({ title, children, delay, subtitle, width, minHeight, zIndex, rightButton }: {
  title: string,
  children: ReactNode,
  delay?: string,
  subtitle?: string,
  width?: string,
  minHeight?: string,
  zIndex?: number,
  rightButton?: ReactNode,
}) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <div
      className="fade-in-component"
      style={{
        "--fade-in-delay": delay ?? "0.15s",
        padding: "26px 46px",
        backgroundColor: "#ffffff",
        border: "1px solid #B9B9B9",
        borderRadius: "16px",
        boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
        width: width ? width : "calc(100vw - 400px)",
        minHeight: minHeight ? minHeight : undefined,
        zIndex: zIndex ? zIndex : undefined,
        position: "relative"
      } as React.CSSProperties}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        alignItems: "center"
      }}>
        <Text size={22} weight={700}>
          {title}
        </Text>
        <div>
          {rightButton}
        </div>
      </div>
      { subtitle && (
        <>
          <Space px={10} />
          <Text size={15} color="#787878">
            {subtitle}
          </Text>
        </>
      )}
      <Space px={15} />
      <Divider />
      <Space px={15} />
      {children}
    </div>
  )
};

export default Container;
